:root {
  --neutral-1: #f0f2f5;
  --neutral-2: #e1e5ea;
  --neutral-3: #d3d8e0;
  --neutral-4: #c4cbd6;
  --neutral-5: #b5becc;

  --neutral-6: #a6b1c1;
  --neutral-7: #97a4b7;
  --neutral-8: #8997ad;
  --neutral-9: #7a8aa2;
  --neutral-10: #6b7d98;

  --neutral-10: #6b7d98;
  --neutral-11: #607189;
  --neutral-12: #56647a;
  --neutral-13: #4b586a;
  --neutral-14: #404b5b;
  --neutral-15: #363f4c;
  --neutral-16: #2b323d;

  --primary: #2768c9;
  --on-primary: #fff;
  --primary-hover: #0d6dfd;
  --primary-active: #2259aa;
  --background: #fff;
  --text: #0e2649;
  --text-secondary: var(--neutral-14);
  --text-link-secondary: var(--neutral-16);
  --highlight: #d90e4f;
  --surface: var(--neutral-1);

  --shadow-1: rgba(2, 23, 83, 0.1);
  --shadow-2: rgba(2, 23, 83, 0.2);
  --shadow-3: rgba(2, 23, 83, 0.3);

  --success: #25a249;
  --success-background: #d3f4dd;
  --success-solid: #12652a;
  --info: #05afff;
  --info-background: #cdefff;
  --info-solid: #035880;
  --warning: #ffb302;
  --warning-background: #fff0cc;
  --warning-solid: #805a01;
  --danger: #de350b;
  --danger-background: #f8d7ce;
  --danger-solid: #6f1b06;

  --space-1: 0.25rem;
  --space-2: 0.5rem;
  --space-3: 1rem;
  --space-4: 2rem;
  --space-5: 4rem;
  --radius: 0.5rem;
  --z-index-1: 1;
  --z-index-10: 10;
  --z-index-100: 100;
  --layout-width: 50vw;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 0.95rem;
  color: var(--text);
}

button {
  font-family: inherit;
  font-size: inherit;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.p-0 {
  padding: 0;
}
.p-1 {
  padding: var(--space-1);
}
.p-2 {
  padding: var(--space-2);
}
.p-3 {
  padding: var(--space-3);
}
.p-4 {
  padding: var(--space-4);
}
.p-5 {
  padding: var(--space-5);
}
.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: var(--space-1);
}
.pt-2 {
  padding-top: var(--space-2);
}
.pt-3 {
  padding-top: var(--space-3);
}
.pt-4 {
  padding-top: var(--space-4);
}
.pt-5 {
  padding-top: var(--space-5);
}
.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: var(--space-1);
}
.pb-2 {
  padding-bottom: var(--space-2);
}
.pb-3 {
  padding-bottom: var(--space-3);
}
.pb-4 {
  padding-bottom: var(--space-4);
}
.pb-5 {
  padding-bottom: var(--space-5);
}
.ps-0 {
  padding-left: 0;
}
.ps-1 {
  padding-left: var(--space-1);
}
.ps-2 {
  padding-left: var(--space-2);
}
.ps-3 {
  padding-left: var(--space-3);
}
.ps-4 {
  padding-left: var(--space-4);
}
.ps-5 {
  padding-left: var(--space-5);
}
.pe-0 {
  padding-right: 0;
}
.pe-1 {
  padding-right: var(--space-1);
}
.pe-2 {
  padding-right: var(--space-2);
}
.pe-3 {
  padding-right: var(--space-3);
}
.pe-4 {
  padding-right: var(--space-4);
}
.pe-5 {
  padding-right: var(--space-5);
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.px-1 {
  padding-left: var(--space-1);
  padding-right: var(--space-1);
}
.px-2 {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}
.px-3 {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}
.px-4 {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}
.px-5 {
  padding-left: var(--space-5);
  padding-right: var(--space-5);
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1 {
  padding-top: var(--space-1);
  padding-bottom: var(--space-1);
}
.py-2 {
  padding-top: var(--space-2);
  padding-bottom: var(--space-2);
}
.py-3 {
  padding-top: var(--space-3);
  padding-bottom: var(--space-3);
}
.py-4 {
  padding-top: var(--space-4);
  padding-bottom: var(--space-4);
}
.py-5 {
  padding-top: var(--space-5);
  padding-bottom: var(--space-5);
}

.m-0 {
  margin: 0;
}
.m-1 {
  margin: var(--space-1);
}
.m-2 {
  margin: var(--space-2);
}
.m-3 {
  margin: var(--space-3);
}
.m-4 {
  margin: var(--space-4);
}
.m-5 {
  margin: var(--space-5);
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: var(--space-1);
}
.mt-2 {
  margin-top: var(--space-2);
}
.mt-3 {
  margin-top: var(--space-3);
}
.mt-4 {
  margin-top: var(--space-4);
}
.mt-5 {
  margin-top: var(--space-5);
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: var(--space-1);
}
.mb-2 {
  margin-bottom: var(--space-2);
}
.mb-3 {
  margin-bottom: var(--space-3);
}
.mb-4 {
  margin-bottom: var(--space-4);
}
.mb-5 {
  margin-bottom: var(--space-5);
}
.ms-0 {
  margin-left: 0;
}
.ms-1 {
  margin-left: var(--space-1);
}
.ms-2 {
  margin-left: var(--space-2);
}
.ms-3 {
  margin-left: var(--space-3);
}
.ms-4 {
  margin-left: var(--space-4);
}
.ms-5 {
  margin-left: var(--space-5);
}
.me-0 {
  margin-right: 0;
}
.me-1 {
  margin-right: var(--space-1);
}
.me-2 {
  margin-right: var(--space-2);
}
.me-3 {
  margin-right: var(--space-3);
}
.me-4 {
  margin-right: var(--space-4);
}
.me-5 {
  margin-right: var(--space-5);
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.mx-1 {
  margin-left: var(--space-1);
  margin-right: var(--space-1);
}
.mx-2 {
  margin-left: var(--space-2);
  margin-right: var(--space-2);
}
.mx-3 {
  margin-left: var(--space-3);
  margin-right: var(--space-3);
}
.mx-4 {
  margin-left: var(--space-4);
  margin-right: var(--space-4);
}
.mx-5 {
  margin-left: var(--space-5);
  margin-right: var(--space-5);
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-1 {
  margin-top: var(--space-1);
  margin-bottom: var(--space-1);
}
.my-2 {
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
}
.my-3 {
  margin-top: var(--space-3);
  margin-bottom: var(--space-3);
}
.my-4 {
  margin-top: var(--space-4);
  margin-bottom: var(--space-4);
}
.my-5 {
  margin-top: var(--space-5);
  margin-bottom: var(--space-5);
}

.overflow-hidden {
  overflow: hidden;
}
